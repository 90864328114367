import React, { useState, useContext, createContext } from "react"

export interface ICartContext {
  saleId?: string
  setSale: (id: string) => void
  cart: Record<string, number>
  updateCart: (id: string, delta: number) => void
  active: boolean
  setActive: (active: boolean) => void
}

const defaultState = {
  cart: {},
  active: true,
}

const CartContext = createContext<ICartContext>(defaultState as ICartContext)

export const useCart = (): ICartContext => useContext(CartContext)

export const CartProvider: React.FC = ({ children }) => {
  const [saleId, setSale] = useState<string>()
  const [cart, setCart] = useState({})
  const [active, setActive] = useState(true)

  const updateCart = (id: string, delta: number) => {
    setCart({
      ...cart,
      [id]: Math.max(0, (cart[id] || 0) + delta),
    })
  }

  return (
    <CartContext.Provider value={{ saleId, setSale, cart, updateCart, active, setActive }}>
      {children}
    </CartContext.Provider>
  )
}
