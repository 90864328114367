import { css } from "styled-components"

import { COLORS, FONTS, SIZES } from "src/helpers/constants"

export default css`
  body {
    background-color: ${COLORS.yellow};
    color: ${COLORS.black};
    font-family: ${FONTS.text};
    font-size: ${SIZES.regular}px;
  }
  a {
    text-decoration: none;
  }
  a,
  button,
  select,
  input,
  textarea {
    outline: none;
    color: inherit;
  }
  button {
    border: none;
    background: none;
    cursor: pointer;
  }
  h1,
  h2,
  h3 {
    font-family: ${FONTS.title};
    font-weight: normal;
  }
  h1 {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1em;
  }
  h2,
  h3 {
    margin-bottom: 0;
  }
`
