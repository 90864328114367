import { GatsbyBrowser } from "gatsby"
import React, { ReactNode } from "react"

import { CartProvider } from "src/helpers/cart"

const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }): ReactNode => (
  <CartProvider>{element}</CartProvider>
)

export default wrapRootElement
