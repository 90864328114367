import React from "react"
import styled, { createGlobalStyle } from "styled-components"

import { ErrorBoundary } from "src/helpers/bugsnag"
import normalize from "./normalize"
import style from "./style"

const GlobalStyles = createGlobalStyle`
  ${normalize}
  ${style}
`

const ErrorContainer = styled.div`
  padding: 20vh 20px;
  text-align: center;
  h1 {
    color: #e10f14;
  }
  h2 {
    font-weight: normal;
    margin-bottom: 10vh;
  }
`

// https://github.com/bugsnag/bugsnag-js/blob/next/packages/plugin-react/src/index.js#L9-L12
interface FallbackProps {
  error: Error
  info?: {
    componentStack: string
  }
}

const ErrorComponent: React.FC<FallbackProps> = () => (
  <ErrorContainer>
    <h1>
      <span aria-hidden>🐞</span>
      <br />
      Un bug est survenu
    </h1>
    <h2>Essayez de recharger la page</h2>
    <p>
      Si le problème persiste, contactez <a href="mailto:antoine@rousseau.im">antoine@rousseau.im</a>
    </p>
  </ErrorContainer>
)

const Page: React.FC = ({ children }) => (
  <>
    <GlobalStyles />
    <ErrorBoundary FallbackComponent={ErrorComponent}>{children}</ErrorBoundary>
  </>
)

export default Page
