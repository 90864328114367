export const COLORS = {
  black: "#000000",
  yellow: "#e6e42e",
  transparentYellow: "rgba(239, 232, 43, 0.85)",
  white: "#ffffff",
  transparentWhite: "rgba(255, 255, 255, 0.85)",
  red: "#DB0000",
}

export const FONTS = {
  title: "'Bebas Neue', 'Arial Narrow', sans-serif",
  text: "'Barlow Condensed', sans-serif",
}

export const SIZES = {
  label: 24,
  menu: 22,
  medium: 20,
  regular: 18,
  small: 14,
}

export const EMAIL_ADDRESS = "contact@lattrapemiel.com"
export const PHONE = "06 50 84 84 08"
export const TEL = "tel:+33650848408"
export const MAPS_URL = "https://goo.gl/maps/xZc1ctPGrB3aYhx67"
export const MAILCHIMP =
  "https://lattrapemiel.us20.list-manage.com/subscribe/post?u=f45519cf3df547d3e38011f47&amp;id=c3c180a1fc"

export const CLOSE_SALE = "CLOSE"
export const SPECIFIC_SALE = "SPECIFIC"
export const FIXED_SALES = [
  {
    id: CLOSE_SALE,
    label: "J’habite en Limousin",
    disclaimer: "Pour connaître les tarifs adaptés et modes de retrait possibles",
  },
  {
    id: SPECIFIC_SALE,
    label: "Je souhaite organiser une distribution",
    disclaimer: "Si vous envisagez une commande groupée entre collègues, entre amis, au sein de votre association…",
  },
]
